import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { fetchWrapper } from '../../utils/fetch-wrapper';
import { isNotEmpty } from '../../utils/utils';
import { API, handleRequest } from '../../utils/fetch-handler';

export async function get(id) {
    if (!id) {
        return;
    }

    let response = await fetchWrapper('/api/cities?countyId=' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response.json();
}

export async function getCityById(id) {
    if (!id) {
        return '';
    }

    let response = await fetchWrapper(`/api/cities/get-by-id?cityId=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response.json();
}

let CountyId = 0;
const cacheCity = new Map();

export function getCitiesDataSource() {
    let store = new CustomStore({
        key: 'Id',
        load: async (loadOptions) => {
            let params = '';
            ['skip', 'take', 'filter'].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });

            params = params.slice(0, -1);
            if (isNotEmpty(loadOptions.searchExpr) && isNotEmpty(loadOptions.searchValue)) {
                params += `&filter=["${loadOptions.searchExpr}","${loadOptions.searchOperation}", "${loadOptions.searchValue}"]`;
            }

            CountyId = loadOptions.userData.CountyId ?? CountyId;
            let response = await fetchWrapper(`/api/cities?countyId=${CountyId}&${params}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.json();
        },
        byKey: (() => {
            return async (keyValue) => {
                const numericKey = Number(keyValue);
                if (cacheCity.has(numericKey)) {
                    return cacheCity.get(numericKey);
                }

                try {
                    const response = await handleRequest(
                        `/api/cities/get-by-id?cityId=${numericKey}`,
                        API.GET
                    );
                    cacheCity.set(numericKey, response);
                    return response;
                } catch (error) {
                    console.error('Eroare la încărcarea detaliilor:', error);
                }
            };
        })(),
    });

    return new DataSource({
        store: store,
        paginate: true,
        pageSize: 10,
    });
}
