import React from 'react';
import ReactDOM from 'react-dom/client';
import './polyfills.js';

import { ErrorBoundary } from 'react-error-boundary';
import App from './Root.jsx';
import reportWebVitals from './reportWebVitals.js';
import ErrorFallback from './utils/ErrorFallback.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.replace('/#/')}>
        <App />
    </ErrorBoundary>
);

reportWebVitals();
