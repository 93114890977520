export const NO_DELETE_MESSAGES = 'Vă rugăm să selectați un mesaj înainte de a apăsa pe ștergere!';
export const DELETE_MESSAGES = 'Sunteți sigur că doriți să ștergeți mesajele selectate?';
export const NO_READ_MESSAGES = 'Vă rugăm să selectați un mesaj înainte de a marca ca fiind citit.';
export const READ_MESSAGES =
    'Sunteți sigur că doriți să marcați ca fiind citit mesajele selectate?';
export const BTN_DX_MESSAGE = 'Marchează ca citit';
export const BTN_DX_DELETE = 'Șterge mesajele';
export const MESSAGE_ART3_PCT3 =
    'Valoarea liniilor de credit / soldul creditelor garantate în condițiile art.3. pct. 3 litera a) din OUG 43/2013 la data solicitării garanției FGCR (se va detalia fiecare credit în moneda în care a fost aprobat):';
export const MESSAGE_REQUEST_WARRANTY_STRUCTURE =
    'Descriere garanții conform contract de credit (valorile sunt în aceeași monedă cu moneda creditului):';
export const CONFIRMATION_TITLE = 'Salvare informații';
export const CONFIRMATION_BODY = '<i>Doriți salvarea datelor?</i>';
export const VALIDATION_MESSAGE = 'Completați câmpurile obligatorii.';
export const SIGHT_VALIDATE_ERROR =
    'Persoana care a introdus/modificat înregistrarea nu poate efectua vizarea acestei operațiuni!';
export const PATTERN_RULES_PASSWORD =
    'Parola trebuie să conțină cel puțin o literă mare, o literă mică, o cifră si un caracter non-alfanumeric!';
export const STRING_LENGTH_PASSWORD = 'Parola trebuie să aibă cel puțin 6 caractere și maximul 24!';
export const STRING_SUCCES_SAVE = 'Datele au fost salvate!';
export const STRING_ERROR_SAVE = 'Datele nu au fost salvate!';
export const VALIDATE_ALL_FIELD_MESSAGE = 'Vă rugăm să completați câmpurile obligatorii!';
export const IMPORT_MESSAGE_COMMISSION_DIALOG =
    '<i>Confirmați importul încasărilor?<br/> Acestea vor suprascrie încasările înregistrate până acum.</i>';
export const FORMAT_ERROR = 'Format-ul este invalid!';
export const ERROR_ACTION_BTN = 'Ne pare rău, această acțiune are o eroare!';
export const SUCCES_ACTION_BTN = 'Aceasteă acțiune a fost executată cu succes!';
export const R38_TITLE = 'Situația rulajului conturilor de solduri (R38)';
export const R39_TITLE = 'Situația rulajului conturilor de cereri de plată (R39)';
export const R37_TITLE = 'Raport solduri garanții contabilitate (R37)';
export const R36_TITLE = 'Raport cereri de plată neonorate contabilitate (R36)';
export const R5_TITLE = 'Raport detaliat (R5)';
export const R9_TITLE = 'Raport detaliat (R9)';
export const R15_1_TITLE =
    'Situatia garantiilor constituite in favoarea FGCR aferente angajamentelor de garantare';
export const R15_2_TITLE = 'Raport situatia garantiilor constituite in favoare FGCR';
export const EXPORT_XLSX = 'Export Excel';
export const EXPORT_PDF = 'Export PDF';
export const EXPORT_CSV = 'Export CSV';
export const FILTER_CAPTION = 'Filtre';
export const DELETE_MESSAGE_NOTIFICATION = 'Confirmați ștergerea mesajului?';

//BANKS ZONE
export const DELETE_BANK = 'Confirmați ștergerea băncii?';
export const NO_DELETE_BANK =
    'Vă rugăm să selectați o bancă/sucursală înainte de a apăsa pe ștergere.';
export const BANKS_TITLE_PAGE = 'Instituții finanțatoare';
export const CHANGE_CAPTION_NAME_BANKS = 'Nume Bancă';
export const CHANGE_CAPTION_CODE_BANKS = 'Cod';
export const AUDIT_BANKS = 'Audit instituții finanțatoare';
export const EXPORT_BANKS_FILE_NAME = 'Instituții_finanțatoare';
export const SUCCES_MESSAGE_DELETE_BANKS = 'Instituția finanțatoare a fost ștersă cu succes!';
export const ADD_BANKS = 'Adăugare Bancă';
export const ADD_AGENCY = 'Adăugare Sucursală';
export const EDIT_BANKS = 'Editare informații';
export const SELECT_BANKS_INFO = 'Vă rugăm să selectați o înregistrare!';
export const SELECT_AGENCY_INFO = 'Pentru adăugarea unei sucursale, selectați o bancă.';

//ADMINISTRATION ZONE
export const CONFIRMATION_BODY_REJECT_USER_BODY = 'Doriți respingerea utilizatorului?';
export const CONFIRMATION_BODY_REJECT_USER_TITLE = 'Respingere utilizator';
export const REJECT_USER_BTN = 'Respingere utilizator';
export const VALIDATE_USER_BTN = 'Validare utilizator';
export const VALIDATE_SELECTED_USER_INFO =
    'Vă rugăm selectați un utilizator înainte de a apăsa pe validare!';
export const NO_USER_FOUND = 'Niciun utilizator nu a fost găsit';
export const EDIT_USER = 'Editare utilizator & roluri';
export const ADD_USER = 'Adăugare utilizator & roluri';
export const VALIDATE_USER_TITLE_PAGE = 'Validare utilizatori externi';
export const EXTERNAL_USERS_TITLE_PAGE = 'Utilizatori externi';
export const INTERNAL_USERS_TITLE_PAGE = 'Utilizatori interni';
export const SUCCESS_CONFIRMATION = 'Contul a fost confirmat cu succes!';
export const WRONG_PARAMS = 'Parametrii confirmării sunt incorecți!';

//GENERAL
export const EDIT = 'Editează';
export const DELETE = 'Șterge';
export const VIEW_TEAM = 'Vizualizare echipă';
export const REJECTED = 'Respingere';
export const DANGER = 'danger';
export const SUCCESS = 'success';
export const INFO = 'info';
export const RESET_PASSWORD = 'Resetare parolă';
export const PDF = 'pdf';
export const CSV = 'csv';
export const XLSX = 'xlsx';

// ICONS
export const CHECK = 'check';
export const CLOSE = 'close';
export const LOCK = 'lock';

//ERROR MESSAGES
export const ERROR_MESSAGES = {
    500: 'Eroare internă de server. Vă rugăm să încercați din nou mai târziu.',
    403: 'Interzis. Nu aveți permisiunea de a accesa această resursă',
    400: 'Cerere greşită. Vă rugăm să verificați imputurile dvs.',
    409: 'Există o înregistrare cu imputurile dvs.',
    401: 'Neautentificat. Vă rugăm să vă autentificați.',
    404: 'Resursa nu a fost găsită.',
    405: 'Metoda nu este permisă.',
    406: 'Nu este acceptabil.',
    408: 'Timpul de aşteptare a expirat.',
    410: 'Resursa a fost eliminată.',
    411: 'Lipsă lungimea conţinutului.',
    412: 'Condiția prealabilă a eșuat.',
    413: 'Conţinutul este prea mare.',
    414: 'Adresa URL este prea lungă.',
    415: 'Tipul de conţinut nu este suportat.',
    416: 'Intervalul de solicitare nu este satisfăcut.',
    417: 'Așteptarea a eșuat.',
    418: 'Tea pot nu este permis.',
    421: 'Solicitarea a fost refuzată.',
    422: 'Entitatea nu poate fi prelucrată.',
    423: 'Resursa este blocată.',
    424: 'Dependența a eșuat.',
    425: 'Prea devreme.',
    426: 'Se solicită actualizare.',
    428: 'Condiția prealabilă este necesară.',
    429: 'Prea multe solicitări.',
    431: 'Câmpurile antetului solicitării sunt prea mari.',
    451: 'Nu este disponibil din motive legale.',
};
