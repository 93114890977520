import { API, handleOutsideRequest, handleRequest } from '../../utils/fetch-handler';

export function getUsers(externalUsers = false, validated = true) {
    return handleRequest(
        '/api/users?externalUsers=' + externalUsers + '&validated=' + validated,
        API.GET
    );
}
export function getById(id) {
    if (!id) return;
    return handleRequest('/api/users/' + id, API.GET);
}
export function getCurrentUser() {
    return handleRequest('/api/users/current-user', API.GET);
}
export function addUser(data) {
    return handleRequest('/api/users', API.POST, data);
}
export function editUser(data) {
    return handleRequest('/api/users', API.PUT, data);
}
export function deleteUser({ UserId, validate = false, ReasonRejection }) {
    if (!UserId) return;
    return handleRequest(
        `/api/users?userId=${UserId}&notValidate=${validate}&message=${ReasonRejection}`,
        API.DELETE
    );
}

export function validateExternalUser({ UserId }) {
    if (!UserId) return;
    return handleRequest('/api/users/validate-external-user?userId=' + UserId, API.POST);
}

export function saveUserInstitutionDetails(data) {
    data.notifyAdmin = false;
    data.externalUser = true;
    return handleRequest('/api/users/save-data-after-2fa', API.POST, data);
}
export function updateUserInstitution(data) {
    data.notifyAdmin = false;
    data.externalUser = true;
    return handleRequest('/api/users/update-user-institution', API.POST, data);
}

export function changePassword(userId, oldPassword, newPassword) {
    let credentials = {};
    credentials.UserId = userId;
    credentials.OldPassword = oldPassword;
    credentials.NewPassword = newPassword;

    return handleRequest('/api/users/change-password', API.POST, credentials);
}

export const confirmedAccount = (data) => {
    return handleOutsideRequest({
        url: `/api/users/confirmation-intern-user`,
        method: API.POST,
       data,
    });
};
