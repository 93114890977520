import notify from 'devextreme/ui/notify';
import { getAuditByZone } from '../api/services/audit-service';
import { handleErrorResponse } from './fetch-handler';
import { findNameById } from '../use/useFindName';
import { stringToLocaleDate } from './utils';
import { DATA_SOURCE } from '../constants/apiKeys';

export const notifyGeneralError = (message) => {
    return notify(message, 'error', 5000);
};

export const notifyGeneralInfo = (message = 'Vă rugăm să selectați o înregistrare!') => {
    return notify(message, 'info', 5000);
};

export const notifyGeneralSucces = (message) => {
    return notify(message, 'success', 5000);
};

export const getDeleteMessage = function (
    selectedId,
    messageDelete = `Confirmați ștergerea înregistrării selectate?`
) {
    if (selectedId) {
        return messageDelete;
    }

    return null;
};

export const handleDeleteFc = async (
    deleteApi,
    id,
    messageSucces = `Selecția a fost ștearsă cu succes!`,
    refresh = false,
    messageAudit = ''
) => {
    try {
        const response = await deleteApi(id, messageAudit);
        await handleErrorResponse(response, 'Selecția nu s-a putut șterge!');
        notifyGeneralSucces(messageSucces);
        refresh && refresh();
    } catch (error) {
        notifyGeneralError(error.message);
    }
};

export const handleAuditButtonClick = async (id, zone, setAudit) => {
    setAudit({ loading: true });
    const auditResponse = await getAuditByZone(id, zone);
    setAudit((prev) => ({ ...prev, popupVisible: true, auditData: auditResponse, loading: false }));
};

export const handleHideAudit = (setAudit) => {
    setAudit({
        popupVisible: false,
        auditData: [],
    });
};

export const pageSizes = [10, 20, 50, 100, 500];

export const handleRowExpanding = function (e) {
    e.component.collapseAll(-1);
};

export const rowGridData = (e) => {
    if (e.selectedRowKeys.length === 1) {
        return e.selectedRowsData[0];
    } else {
        return null;
    }
};

export const rowGridId = (e) => {
    if (e.selectedRowKeys.length === 1) {
        return e.selectedRowKeys[0];
    } else {
        return null;
    }
};

export const onClumnClickPopover = (e, ref, setPopValue) => {
    const valueCell = e?.cellElement?.getAttribute('title');
    if (!['data'].includes(e.rowType) || !valueCell) return;
    const valueColumns = e?.text;
    setPopValue(valueColumns);
    ref.current.instance().option('target', e.cellElement);
    ref.current.instance().show();
};

export const onExportGridBank = async (
    setLoading,
    dataBank,
    dataSource,
    getExcel,
    fileName = 'FGCR_SOLD_',
    date = 'BalanceDate'
) => {
    setLoading(true);
    const findBankName = findNameById(dataBank, dataSource.BankId);
    const responseBlob = await getExcel(dataSource);
    const fileURL = window.URL.createObjectURL(responseBlob);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `${fileName}${findBankName}_${stringToLocaleDate(dataSource[date])}.xlsx`;
    alink.click();
    setLoading(false);
};

export const validateField = (e, value1, value2, message) => {
    if (value1 > value2) {
        e.isValid = false;
        e.errorText = message;
    }
};

export const countRow = (cellElement, cellInfo) => {
    cellElement.append(cellInfo.rowIndex + 1);
};

export const onExporRaport = async (
    setLoading,
    dataSource,
    getExcel,
    fileName = 'FGCR',
    date = ''
) => {
    setLoading(true);
    const responseBlob = await getExcel(dataSource);
    const fileURL = window.URL.createObjectURL(responseBlob);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `${fileName}_${date}.xlsx`;
    alink.click();
    setLoading(false);
};

export const notifyErrorFetchData = () => {
    return notify(
        'Datele nu au putut fi aduse, vă rugăm să încercați din nou mai târziu!',
        'error',
        3000
    );
};

export const onGeneralExportFile = (responseBlob, fileName = 'FGCR') => {
    const fileURL = window.URL.createObjectURL(responseBlob);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `${fileName}`;
    alink.click();
};

export function onCompareDataSource(oldData, newData, fetchChangeDataSource) {
    if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
        fetchChangeDataSource(DATA_SOURCE);
    }
}
