import permissionsService from '../services/Permissions';

export function getParamsAttribute(loadOptions) {
    let params = '';
    ['skip', 'take', 'requireTotalCount', 'sort', 'filter'].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
    });

    params = params.slice(0, -1);

    return params;
}

export function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export function onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
}

export function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export function isNullOrEmpty(str) {
    return (
        !str ||
        str === null ||
        str === undefined ||
        str === 'null' ||
        str === '' ||
        str === '{}' ||
        str === 'undefined' ||
        str.length === 0
    );
}

export function getElementWidth(elementId) {
    let element = document.getElementById(elementId);
    return element.clientWidth;
}

export function getElementHeight(elementId) {
    let element = document.getElementById(elementId);
    return element.clientHeight;
}

export function downlodBitesArrayFile(file, fileName) {
    const decodedString = file.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
    try {
        const binaryString = window.atob(decodedString);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
            'download',
            encodeURIComponent(fileName.replace(/[^a-zA-Z0-9.\-_]/g, ''))
        );

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Invalid file data provided', error);
    }
}
export function getFormattedCurrentDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + '-' + mm + '-' + dd;
}

export function getFormattedDate(date) {
    const yyyy = date?.getFullYear();
    let mm = date?.getMonth() + 1;
    let dd = date?.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + '-' + mm + '-' + dd;
}

export const patterns = {
    patternCNP:
        /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/,

    patternCUI: /^(RO\s+)?[1-9][0-9]{1,9}$/,
    patternCommerceRegister: 'J|j[0-9]{2}/[0-9]+/(19|20)([0-9]{2})',
    patternForPassword: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-={}|;:,.<>?/]).{6,}$/,
};

export function validCNP(p_cnp) {
    if (!p_cnp) return true;
    let i = 0,
        year = 0,
        hashResult = 0,
        cnp = [],
        hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
    if (p_cnp.length !== 13) {
        return false;
    }
    for (i; i < 13; i++) {
        cnp[i] = parseInt(p_cnp.charAt(i), 10);
        if (Number.isNaN(cnp[i])) {
            return false;
        }
        if (i < 12) {
            hashResult = hashResult + cnp[i] * hashTable[i];
        }
    }
    hashResult = hashResult % 11;
    if (hashResult === 10) {
        hashResult = 1;
    }
    year = cnp[1] * 10 + cnp[2];
    switch (cnp[0]) {
        case 1:
        case 2:
            year += 1900;
            break;
        case 3:
        case 4:
            year += 1800;
            break;
        case 5:
        case 6:
            year += 2000;
            break;
        case 7:
        case 8:
        case 9:
            year += 2000;
            if (year > parseInt(new Date().getFullYear(), 10) - 14) {
                year -= 100;
            }

            break;
        default: {
            return false;
        }
    }
    if (year < 1800 || year > 2099) {
        return false;
    }
    return cnp[12] === hashResult;
}

export function validateRomanianCUI(v) {
    if (typeof v !== 'string') {
        return 'Nu e string!';
    }

    let cif = v.toUpperCase();
    // remove RO from cif:
    cif = cif.indexOf('RO') > -1 ? cif.substring(2) : cif;
    // remove spaces:
    cif = cif.replace(/\s/g, '');
    // validate character length:
    if (cif.length < 2 || cif.length > 10) {
        return 'Lungimea corectă fără RO, este între 2 și 10 caractere!';
    }
    // validate that so far the resulting CIF looks like an integer value:
    if (Number.isNaN(parseInt(cif))) {
        return 'Nu este număr!';
    }
    // begin testing:
    const testKey = '753217532';
    const controlNumber = parseInt(cif.substring(cif.length - 1));
    // remove control number:
    cif = cif.substring(0, cif.length - 1);
    // pad left with zeros to reach testKey length:
    while (cif.length !== testKey.length) {
        cif = '0' + cif;
    }
    let sum = 0;
    let i = cif.length;

    while (i--) {
        sum = sum + parseInt(cif.charAt(i)) * parseInt(testKey.charAt(i));
    }

    let calculatedControlNumber = (sum * 10) % 11;

    if (calculatedControlNumber === 10) {
        calculatedControlNumber = 0;
    }

    return controlNumber === calculatedControlNumber;
}

export const selectSource = (dataKey, dataMap) => {
    const data = dataMap[dataKey];
    return data || [];
};

export const calcProcentage = (value1, value2) => {
    return (value1 / value2) * 100;
};

export const hintSelectBoxLine = (e, text = 'Name') => {
    return "<div class='custom-item' title='" + e[text] + "'>" + e[text] + '</div>';
};

export const helpTextFc = (text, companyConfirmed, data, updateDifferences, differences) => {
    return !companyConfirmed
        ? `${text} nu se află în nomenclator. Pentru a adăuga ${data} vă rugăm sa salvați datele!`
        : differences && `Valoare nomenclator : ${updateDifferences}`;
};

export const valueToLocaleString = (data) => {
    return data?.toLocaleString('ro-RO');
};

export const canAction = (selectedRowId, ref, arrIndex) => {
    if (selectedRowId > 0) {
        let isOK = false;
        const items = ref?.current?.instance()?.getDataSource()?.items() || [];
        const found = items.find(({ Id }) => Id === selectedRowId);
        if (arrIndex.includes(found.StatusId)) {
            isOK = true;
        }
        return isOK;
    } else {
        return false;
    }
};

export function findValueIds(dataSource) {
    if (!dataSource) return;
    return dataSource?.map((value) => value.Id).join(',');
}

export function findValue(dataSource) {
    if (!dataSource) return;
    return dataSource?.map((value) => value.Value).reduce((acc, v) => acc + v, 0);
}

export const onEditorOptionsChangePassword = (visiblePass, setVisiblePass) => {
    return {
        stylingMode: 'outlined',
        inputAttr: {
            autocomplete: 'new-password',
        },
        placeholder: '',
        mode: visiblePass ? 'password' : 'text',
        buttons: [
            {
                name: 'password',
                location: 'after',
                options: {
                    icon: visiblePass ? 'eyeopen' : 'eyeclose',
                    type: 'default',
                    onClick: () => setVisiblePass((prevState) => !prevState),
                },
            },
        ],
    };
};

export const fcForValidation = (ref) => {
    const validateForm = ref.current.instance().validate();
    return !validateForm.isValid;
};

export const checkPermission = (permissionString) => {
    return permissionsService.userHasPermission(permissionString);
};

export const onFieldChange = (formData, setDataSource) => {
    const { dataField, value } = formData;
    setDataSource((prev) => ({ ...prev, [dataField]: value }));
};

export const stringToLocaleDate = (date) => {
    return new Date(date).toLocaleDateString('ro-RO');
};

export const calculateAmountCommissionDue = (
    BalanceWarranty,
    CommissionPercentage,
    MonthNumber
) => {
    const result = ((BalanceWarranty / (CommissionPercentage * 100)) * MonthNumber) / 12;
    return !Number.isNaN(result) ? +result.toFixed(4) : 0;
};

export const calculateBalanceWarranty = (value, procentage) => {
    return (value * procentage) / 100;
};

export function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export const onVerifyValidationGroup = (e) => {
    const validateForm = e?.validationGroup?.validate();
    validateForm?.brokenRules[0]?.validator?.focus();
    return !validateForm?.isValid;
};

export const onRequairedByConvention = (isRequired, conventionType, includes = true) => {
    if (typeof isRequired === 'boolean') {
        return isRequired;
    }

    if (includes) {
        return isRequired?.includes(conventionType ?? 0);
    }
    return !isRequired?.includes(conventionType ?? 0);
};
